import { inject }                           from 'aurelia-framework';
import { AggregatesApplicationsRepository } from 'modules/management/aggregates/aggregates-applications/services/repository';
import { AssociationApplication }           from 'modules/management/models/association-application';
import { SectorsRepository }                from 'modules/management/specifications/sectors/services/repository';

@inject(SectorsRepository, AggregatesApplicationsRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param sectorRepository
     * @param applicationsRepository
     */
    constructor(sectorRepository, applicationsRepository) {
        this.sectorRepository       = sectorRepository;
        this.applicationsRepository = applicationsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {AssociationApplication}
     */
    model() {
        let model = new AssociationApplication();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @param create
     * @returns {*[]}
     */
    schema(viewModel, readonly = false, create = false) {
        this.sector_id = {
            type:         'select2',
            key:          'sector_id',
            label:        'form.field.sector',
            size:         12,
            remoteSource: () => this.sectorRepository.all(),
            attributes:   {
                readonly: readonly,
            },
        };

        this.applications = {
            type:         'duallistbox',
            key:          'applications',
            label:        'form.field.application',
            size:         12,
            remoteSource: () => this.applicationsRepository.all(),
            attributes:   {
                disabled: readonly,
            },
        };

        const schema = create ? [
            [this.sector_id],
            [this.applications],
        ] : [
            [this.applications],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('management.aggregates.association-applications.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
